body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.modal {
  backdrop-filter: blur(10px);
}
.progress {
  background: #353749 !important;
  border: 1px solid #ffffff !important;
  border-radius: 0 !important;
}

.progress-bar {
  background: #ffffff !important;
  margin: 2px !important;
}

.carousel-control-next-icon {
  background-image: url("./images/carousel-next-icon.svg") !important;
}

.carousel-control-prev-icon {
  background-image: url("./images/carousel-prev-icon.svg") !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: 25% !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-size: auto !important;
}

.carousel-control-prev {
  left: -40px !important;
  display: none !important;
}

.carousel-control-next {
  right: -40px !important;
  display: none !important;
}
/* 769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV */

@media only screen and (min-width: 1366px) {
  .map-container {
    margin-top: 2rem !important;
  }
  .map-splitted-image {
    width: 60px !important;
  }
}

@media only screen and (min-width: 1536px) {
  .map-container {
    margin-top: 2rem !important;
  }
  .map-splitted-image {
    width: 70px !important;
  }
}

@media only screen and (min-width: 1920px) {
  .map-container {
    margin-top: 2rem !important;
  }
  .map-splitted-image {
    width: 80px !important;
  }
}
